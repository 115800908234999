import Image from "next/image";
import light from "../../../public/images/about/sun-01.svg";
import dark from "../../../public/images/about/vector.svg";

const DarkSwitch = ({ isLight, switchTheme }) => {
  return (
    <div>
      <ul onClick={switchTheme} style={{ listStyleType: "none", paddingBottom: 8, paddingLeft: 0, paddingRight: 0}}>
        {isLight ? (
          <li>
            <button
              data-theme="light"
              className="setColor light"
              style={{ border: "none", background: "none" }}
            >
              <Image src={dark} alt="Sun images" />
            </button>
          </li>
        ) : (
          <li>
            <button
              data-theme="dark"
              className="setColor dark"
              style={{ border: "none", background: "none" }}
            >
              <Image src={light} alt="Vector Images" />
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default DarkSwitch;
