// import { useDispatch, useSelector, useStore } from 'react-redux'

import { useEffect, useState } from "react";

// // Use throughout your app instead of plain `useDispatch` and `useSelector`
// export const useAppDispatch = useDispatch
// export const useAppSelector = useSelector
// export const useAppStore = useStore


  
  export const useDebounced = ({ searchQuery, delay }) => {
    const [debouncedValue, setDebouncedValue] = useState(searchQuery);
  
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(searchQuery);
      }, delay);
  
      return () => {
        clearTimeout(handler);
      };
    }, [searchQuery, delay, setDebouncedValue]);
  
    return debouncedValue;
  };
  