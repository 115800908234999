/* eslint-disable no-sequences */

import { baseApi } from "../baseApi";

const otpApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    sendOtp: build.mutation({
      query: (authData) => ({
        url: "/auth/sent-otp",
        method: "POST",
        data: authData,
      }),
      invalidatesTags: ["auth"],
    }),
    verifyOtp: build.mutation({
      query: (otpData) => ({
        url: "/auth/verify-otp",
        method: "POST",
        data: otpData,
      }),
      invalidatesTags: ["auth"],
    }),
    logOut: build.mutation({
      query: () => ({
        url: "/auth/logout",
        method: "POST",
      }),
      invalidatesTags: ["auth"],
    }),
    logOutAll: build.mutation({
      query: (data) => ({
        url: "/auth/logout-all",
        method: "POST",
        data: data
      }),
      invalidatesTags: ["auth"],
    }),
  }),
});

export const { useSendOtpMutation, useVerifyOtpMutation, useLogOutMutation, useLogOutAllMutation } = otpApi;
