/* eslint-disable no-sequences */

import {baseApi} from "../baseApi";

const publicCourseApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // uploadFile: build.mutation({
    //     query: (data) => ({
    //         url: "/aws/upload",
    //         method: "POST",
    //         data,
    //     }),
    // }),
    publicCourseGet: build.query({
      query: (params) => ({
        url: "/course/approved/public",
        method: "GET",
        params
      }),
    }),

    publicCourseGetBySlug: build.query({
      query: (slug) => ({
        url: `/course/${slug}/public`,
        method: "GET",
      }),
    }),
    publicCourseGetById: build.query({
      query: (id) => ({
        url: `/course/${id}`,
        method: "GET",
      }),
    }),
    publicCategoryGet: build.query({
      query: () => ({
        url: "/course-category/public",
        method: "GET",
      }),
    }),
  }),
});

export const {
  usePublicCourseGetQuery,
  usePublicCategoryGetQuery,
  usePublicCourseGetByIdQuery,
  usePublicCourseGetBySlugQuery,
} = publicCourseApi;
