import Link from "next/link";
import Image from "next/image";
import debounce from "debounce";

import { useAppContext } from "@/context/Context";
import { usePublicCourseGetQuery } from "@/redux/api/publicApi/course";
import { Loader } from "rsuite";
import { useEffect, useState } from "react";
import { useAddWishlistMutation } from "@/redux/api/user/user";
import { useDebounced } from "@/redux/hooks";

const Search = () => {
  const { search, toggle } = useAppContext();

  const [title, setTitle] = useState("");
  const [query, setQuery] = useState("");
  const { data, isLoading, refetch } = usePublicCourseGetQuery({
    title: query,
  });

  const [courses, setCourse] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const startIndex = (page - 1) * 6;

  const getSelectedCourse = courses?.slice(startIndex, startIndex + 6);

  const [addWishlist] = useAddWishlistMutation();

  const addToWishlist = async (product) => {
    const data = {
      coursesId: product?.id,
      quizId: null,
    };
    const res = await addWishlist(data);
    if (res?.data?.success === true) {
      toast.success(res?.data?.message);
    }
  };
  const handleClick = (num) => {
    setPage(num);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setCourse(data?.data);
    setTotalPages(Math.ceil(data?.data?.length / 6));
  }, [setTotalPages, setCourse, getSelectedCourse, data?.data]);

  const getPriceAfterDiscount = (data) => {
    let discountAmount =
      (data?.CurrentCourseBatch?.CourseBatch?.discountPercentage / 100) *
      data?.CurrentCourseBatch?.CourseBatch?.price;

    return data?.CurrentCourseBatch?.CourseBatch?.price - discountAmount;
  };
  const debounceValue = useDebounced({ searchQuery: title, delay: 1000 });
  useEffect(() => {
    setQuery(debounceValue);
    // refetch();
  }, [debounceValue, refetch]);

  return (
    <>
      <div className={`rbt-search-dropdown ${!search ? "active" : ""}`}>
        <div className="wrapper">
          <div className="row">
            <div className="col-lg-12">
              <form>
                <input
                  type="text"
                  placeholder="What are you looking for?"
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />

                {/* <div className="submit-btn">
                  <button
                    type="button"
                    className="rbt-btn btn-gradient btn-md"
                    // onClick={() => refetch()}
                  >
                    Search
                  </button>
                </div> */}
              </form>
            </div>
          </div>

          <div className="rbt-separator-mid">
            <hr className="rbt-separator m-0" />
          </div>

          <div className="row g-4 pt--30 pb--60">
            <div className="col-lg-12">
              <div className="section-title">
                <h5 className="rbt-title-style-2">Our Top Course</h5>
              </div>
            </div>

            {isLoading ? (
              <Loader />
            ) : (
              <div
                className={`rbt-course-grid-column ${
                  !toggle ? "active-list-view" : ""
                }`}
              >
                {" "}
                {data?.data?.map((data, index) => (
                  <div className="course-grid-3" key={index}>
                    <div className="rbt-card variation-01 rbt-hover">
                      <div
                        className={`rbt-card variation-01 rbt-hover ${
                          !toggle ? "card-list-2" : ""
                        }`}
                      >
                        <div className="rbt-card-img">
                          <Link href={`/course-details/${data.slug}`}>
                            <Image
                              src={`${process.env.NEXT_PUBLIC_FILE_BASE}${data?.thumbnail}`}
                              width={355}
                              height={244}
                              alt="Card image"
                            />
                            {data?.CurrentCourseBatch?.CourseBatch
                              ?.discountPercentage > 0 && (
                              <div className="rbt-badge-3 bg-white">
                                <span>
                                  {
                                    data?.CurrentCourseBatch?.CourseBatch
                                      ?.discountPercentage
                                  }{" "}
                                  %
                                </span>
                                <span>Off</span>
                              </div>
                            )}
                          </Link>
                        </div>
                        <div className="rbt-card-body">
                          <div className="rbt-card-top">
                            <div className="rbt-review">
                              <div className="rating">
                                {/* <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i> */}
                              </div>
                              <span className="rating-count">
                                {/* ({data?.review} Reviews) */}
                              </span>
                            </div>
                            <div
                              className="rbt-bookmark-btn"
                              onClick={() => addToWishlist(data)}
                            >
                              <button
                                type="button"
                                className="rbt-round-btn"
                                title="Bookmark"
                              >
                                <i className="feather-bookmark"></i>
                              </button>
                            </div>
                          </div>

                          <h4 className="rbt-card-title">
                            <Link href={`/course-details/${data?.slug}`}>
                              {data?.title}
                            </Link>
                          </h4>

                          <ul className="rbt-meta">
                            <li>
                              {/* <i className="feather-book"></i>
            
                              Lessons */}
                            </li>
                            <li>
                              <i className="feather-users"></i>
                              {
                                data?.CurrentCourseBatch?.CourseBatch
                                  ?.availableSeatsCount
                              }{" "}
                               সিট বাকি
                            </li>
                          </ul>

                          <p className="rbt-card-text">{data.desc}</p>
                          <div className="rbt-author-meta mb--10">
                            <div className="rbt-avater">
                              <Link href={`/course-details/${data?.slug}`}>
                                <Image
                                  src={`${process.env.NEXT_PUBLIC_FILE_BASE}${data?.Instructor?.User?.profileImageUrl}`}
                                  width={33}
                                  height={33}
                                  alt={data?.Instructor?.User?.name}
                                />
                              </Link>
                            </div>
                            <div className="rbt-author-info">
                              By{" "}
                              <Link href={`/profile/${data?.id}`}>
                                {data?.Instructor?.User?.name}
                              </Link>{" "}
                              {/* In <Link href="#">{data.userCategory}</Link> */}
                            </div>
                          </div>
                          <div className="rbt-card-bottom">
                            <div className="rbt-price">
                              <span className="current-price">
                                ৳ {getPriceAfterDiscount(data)}
                              </span>
                              {data?.CurrentCourseBatch?.CourseBatch
                                ?.discountPercentage > 0 && (
                                <span className="off-price">
                                  ৳{" "}
                                  {data?.CurrentCourseBatch?.CourseBatch?.price}
                                </span>
                              )}
                            </div>
                            <Link
                              className="rbt-btn-link"
                              href={`/course-details/${data.slug}`}
                            >
                              Learn More<i className="feather-arrow-right"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Search;
