/* eslint-disable no-sequences */

import { baseApi } from "../baseApi";

const instructorApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    becomeAInstructor: builder.mutation({
      query: (instructorData) => ({
        url: "/instructor/new",
        method: "POST",
        data: instructorData,
      }),
      invalidatesTags: ["instructor"],
    }),
    getAllInstructor: builder.query({
      query: () => ({
        url: "/instructor/public",
        method: "GET",
      }),
      providesTags: ["instructor"],
    }),
    getInstructorStatus: builder.query({
      query: () => ({
        url: "/instructor/status",
        method: "GET",
      }),
      providesTags: ["instructor"],
    }),
    getInstructorStats: builder.query({
      query: () => ({
        url: "/instructor/stats",
        method: "GET",
      }),
      providesTags: ["instructor"],
    }),
    getSingleInstructorByID: builder.query({
      query: (id) => ({
        url: `/instructor/${id}`,
        method: "GET",
      }),
      providesTags: ["instructor"],
    }),
    approvedInstructor: builder.mutation({
      query: ({ id, slug }) => ({
        url: `/instructor/approved/${id}`,
        method: "PUT",
        data: slug,
      }),
      invalidatesTags: ["instructor"],
    }),
    rejectedInstructor: builder.mutation({
      query: ({ id, data }) => ({
        url: `/instructor/rejected/${id}`,
        method: "PUT",
        data,
      }),
      invalidatesTags: ["instructor"],
    }),
    reapplyInstructor: builder.mutation({
      query: ({id,instructorData}) => ({
        url: `/instructor/reapply/${id}`,
        method: "PUT",
        data:instructorData
      }),
      invalidatesTags: ["instructor"],
    }),
    annauncementCreate: builder.mutation({
      query: (announcementData) => ({
        url: `/announcement/new`,
        method: "POST",
        data: announcementData,
      }),
      invalidatesTags: ["announcement"],
    }),
    annauncementDelete: builder.mutation({
      query: (id) => ({
        url: `/announcement/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["announcement"],
    }),
    annauncementGet: builder.query({
      query: () => ({
        url: `/announcement`,
        method: "GET",
      }),
      providesTags: ["announcement"],
    }),
    notification: builder.query({
      query: () => ({
        url: `/notification`,
        method: "GET",
      }),
      providesTags: ["announcement"],
    }),
    allNotification: builder.query({
      query: () => ({
        url: `/user/notifications`,
        method: "GET",
      }),
      providesTags: ["announcement"],
    }),
    markAsRead: builder.mutation({
      query: (id) => ({
        url: `/notification/mark-as-read/${id}`,
        method: "PUT",
      }),
      providesTags: ["announcement"],
    }),
  }),
});

export const {
  useBecomeAInstructorMutation,
  useGetAllInstructorQuery,
  useApprovedInstructorMutation,
  useRejectedInstructorMutation,
  useGetSingleInstructorByIDQuery,
  useGetInstructorStatusQuery,
  useReapplyInstructorMutation,
  useGetInstructorStatsQuery,
  useAnnauncementCreateMutation,
  useAnnauncementGetQuery,
  useAnnauncementDeleteMutation,
  useNotificationQuery,
  useAllNotificationQuery,
  useMarkAsReadMutation
} = instructorApi;
