/* eslint-disable no-sequences */

import {baseApi} from "../baseApi";

const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => ({
        url: "/user/profile",
        method: "GET",
      }),
      providesTags: ["user"],
    }),
    getUserCourses: builder.query({
      query: () => ({
        url: "/user/courses",
        method: "GET",
      }),
      providesTags: ["user"],
    }),
    getUserQuizzes: builder.query({
      query: () => ({
        url: "/user/quizzes",
        method: "GET",
      }),
      providesTags: ["user"],
    }),
    updateProfile: builder.mutation({
      query: (data) => ({
        url: "/user/profile",
        method: "PUT",
        data,
      }),
      invalidatesTags: ["user"],
    }),
    updateReferBonus: builder.mutation({
      query: (data) => ({
        url: "/user/refer-bonus",
        method: "PUT",
        data,
      }),
      invalidatesTags: ["user"],
    }),
    addWishlist: builder.mutation({
      query: (data) => ({
        url: "/wishlist/new",
        method: "POST",
        data,
      }),
      invalidatesTags: ["wishlist"],
    }),

    getWishlist: builder.query({
      query: () => ({
        url: "/wishlist",
        method: "GET",
      }),
      providesTags: ["wishlist"],
    }),
    removeWishlist: builder.mutation({
      query: (id) => ({
        url: `/wishlist/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["wishlist"],
    }),
  }),
});

export const {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useGetUserCoursesQuery,
  useGetUserQuizzesQuery,
  useAddWishlistMutation,
  useGetWishlistQuery,
  useRemoveWishlistMutation,
  useUpdateReferBonusMutation,
} = userApi;
