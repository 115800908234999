import Link from "next/link";
import {useState} from "react";

import {usePublicCategoryGetQuery} from "@/redux/api/publicApi/course";
import {useAppContext} from "@/context/Context";
const Nav = () => {
  const {isLightTheme} = useAppContext();
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const toggleMenuItem = (item) => {
    setActiveMenuItem(activeMenuItem === item ? null : item);
  };
  const {data} = usePublicCategoryGetQuery();
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li className="with-megamenu has-menu-child-item position-static">
          <Link
            className={`${
              (activeMenuItem === "home" ? "open" : "",
              isLightTheme === false ? "text-white" : "text-black")
            }  `}
            onClick={() => toggleMenuItem("home")}
            href="/">
            হোম
          </Link>
        </li>

        <li className="with-megamenu has-menu-child-item">
          <Link
            className={`${
              (activeMenuItem === "courses" ? "open" : "",
              isLightTheme === false ? "text-white" : "text-black")
            }`}
            href="/courses"
            onClick={() => toggleMenuItem("courses")}>
            সকল কোর্স
          </Link>
        </li>

        <li className="has-dropdown has-menu-child-item">
          <Link
            className={`${
              (activeMenuItem === "ielts" ? "open" : "",
              isLightTheme === false ? "text-white" : "text-black")
            }`}
            href="/categories"
            onClick={() => toggleMenuItem("ielts")}>
            ক্যাটাগরি
            <i className="feather-chevron-down"></i>
          </Link>
          <ul
            className={`submenu ${
              activeMenuItem === "ielts" ? "active d-block" : ""
            }`}>
            <li className="has-dropdown">
              {data?.data?.map((category, index) => (
                <Link key={index} href={`/courses/${category?.slug}`}>
                  {category?.name}
                </Link>
              ))}
            </li>
          </ul>
        </li>
        <li className="has-dropdown has-menu-child-item">
          <Link
            className={`${
              (activeMenuItem === "ielts" ? "open" : "",
              isLightTheme === false ? "text-white" : "text-black")
            }`}
            href="#"
            onClick={() => toggleMenuItem("ielts")}>
            MCQ পরীক্ষা
            <i className="feather-chevron-down"></i>
          </Link>
          <ul
            className={`submenu ${
              activeMenuItem === "ielts" ? "active d-block" : ""
            }`}>
            <li className="has-dropdown">
              <Link href="/freequiz">ফ্রী MCQ পরীক্ষা</Link>
              <Link href="/specialquiz">স্পেশাল MCQ পরীক্ষা</Link>
            </li>
          </ul>
        </li>
        <li className="has-dropdown has-menu-child-item">
          <Link
            className={`${
              (activeMenuItem === "ielts" ? "open" : "",
              isLightTheme === false ? "text-white" : "text-black")
            }`}
            href="#"
            onClick={() => toggleMenuItem("ielts")}>
            লিখিত পরীক্ষা
            <i className="feather-chevron-down"></i>
          </Link>
          <ul
            className={`submenu ${
              activeMenuItem === "ielts" ? "active d-block" : ""
            }`}>
            <li className="has-dropdown">
              <Link href="/freewritten">ফ্রী লিখিত পরীক্ষা</Link>
              <Link href="/specialwritten">স্পেশাল লিখিত পরীক্ষা</Link>
            </li>
          </ul>
        </li>

        <li className="with-megamenu has-menu-child-item position-static">
          <Link
            href="/about-us"
            className={`${
              (activeMenuItem === "about" ? "open" : "",
              isLightTheme === false ? "text-white" : "text-black")
            }`}
            onClick={() => toggleMenuItem("about")}>
            আমাদের সম্পর্কে
          </Link>
        </li>
        {/* <li className="with-megamenu has-menu-child-item position-static">
          <Link
            href="/memberships"
            className={`${activeMenuItem === "memberships" ? "open" : ""}`}
            onClick={() => toggleMenuItem("memberships")}>
            সদস্যপদ
          </Link>
        </li> */}

        <li className="has-dropdown has-menu-child-item">
          <Link
            className={`${
              (activeMenuItem === "ielts" ? "open" : "",
              isLightTheme === false ? "text-white" : "text-black")
            }`}
            href="#"
            onClick={() => toggleMenuItem("ielts")}>
            আরো
            <i className="feather-chevron-down"></i>
          </Link>
          <ul
            className={`submenu ${
              activeMenuItem === "ielts" ? "active d-block" : ""
            }`}>
            <li className="has-dropdown">
              <Link href="/blogs"> ব্লগ</Link>
              <Link href="/jobs">ক্যারিয়ার / নিয়োগ বিজ্ঞপ্তি</Link>
              <Link
                href="/contact"
                className={`${activeMenuItem === "contact" ? "open" : ""}`}>
                যোগাযোগ
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};
export default Nav;
