import React, {useState} from "react";
import {Formik, Form, Field, ErrorMessage, FieldArray} from "formik";
import * as Yup from "yup";

import {toast} from "react-toastify";



import Select from "react-select";
import {usePublicCourseGetQuery} from "@/redux/api/publicApi/course";
import {useCreateLeadMutation} from "@/redux/api/superAdmin/lead";

const LeadModal = ({close}) => {


  const [createLead, {isLoading}] = useCreateLeadMutation();
  const {data} = usePublicCourseGetQuery();
  const myCoursesOptions = data?.data?.map((item) => ({
    value: item?.id && item?.id,
    label: item?.title && item?.title,
  }));

  const initialValues = {
    name: "",
    email: "",
    mobileNumber: "",
    address: "",
    coursesId: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().optional("Please enter a valid email address"),
    mobileNumber: Yup.string().optional("Please enter a valid mobile number"),
  });

  const handleSubmit = async (values, {resetForm}) => {
    const leadData = {...values};

    const res = await createLead(leadData);
    if (res?.data?.success) {
      toast.success(res?.data?.message);
      close();
      resetForm(initialValues);
    }
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 100,
    }),
  };
  return (
    <div>
      <div
        className="modal show d-block rbt-team-modal modal fade rbt-modal-default"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModal"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                onClick={close}
                type="button"
                className="rbt-round-btn"
                data-bs-dismiss="modal"
                aria-label="Close">
                <i className="feather-x"></i>
              </button>
            </div>
            <div className="modal-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                {({values, setFieldValue}) => (
                  <Form>
                    <div className="row g-5">
                      <div className="col-lg-12 ">
                        <div
                          className="accordion"
                          id="tutionaccordionExamplea1">
                          <div className="">
                            <div
                              id="accCollapseOne"
                              className="accordion-collapse collapse show"
                              aria-labelledby="accOne"
                              data-bs-parent="#tutionaccordionExamplea1">
                              <div className="accordion-body card-body">
                                {/* Start Course Field Wrapper  */}
                                <div className="rbt-course-field-wrapper rbt-default-form">
                                  <div className="course-field mb--15">
                                    <label htmlFor="name">Name</label>
                                    <Field
                                      id="name"
                                      type="text"
                                      name="name"
                                      placeholder="Enter Name"
                                    />
                                    <ErrorMessage
                                      name="name"
                                      component="div"
                                      className="error-message"
                                    />
                                  </div>

                                  <div className="course-field mb--15">
                                    <label htmlFor="email">Email</label>
                                    <Field
                                      id="email"
                                      type="text"
                                      name="email"
                                      placeholder="Enter Email"
                                    />
                                    <ErrorMessage
                                      name="email"
                                      component="div"
                                      className="error-message"
                                    />
                                  </div>
                                  <div className="course-field mb--15">
                                    <label htmlFor="mobileNumber">
                                      Mobile Number
                                    </label>
                                    <Field
                                      id="mobileNumber"
                                      type="text"
                                      name="mobileNumber"
                                      placeholder="Enter Mobile Number"
                                    />
                                    <ErrorMessage
                                      name="mobileNumber"
                                      component="div"
                                      className="error-message"
                                    />
                                  </div>

                                  <div className="col-12">
                                    <div className="course-field mb--20">
                                      <h6>Choose Course</h6>
                                      <Field
                                        className=" w-100"
                                        id="coursesId"
                                        name="coursesId">
                                        {(props) => {
                                          const handleChange = (option) => {
                                            props.form.setFieldValue(
                                              "coursesId",
                                              option.value
                                            );
                                          };
                                          return (
                                            <Select
                                              classNamePrefix="react-select "
                                              options={myCoursesOptions}
                                              isSearchable
                                              styles={customStyles}
                                              onChange={handleChange}
                                            />
                                          );
                                        }}
                                      </Field>
                                      <ErrorMessage
                                        name="coursesId"
                                        component="div"
                                        className="error-message text-danger"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="course-field mb--15">
                                      <label htmlFor="address">Address</label>
                                      <Field
                                        id="address"
                                        type="text"
                                        name="address"
                                        placeholder="Enter Address"
                                      />
                                      <ErrorMessage
                                        name="address"
                                        component="div"
                                        className="error-message"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* End Course Field Wrapper  */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt--10 row g-5">
                          <div className="col-lg-4">
                            <button
                              type="submit"
                              className="rbt-btn btn-gradient hover-icon-reverse w-100 text-center">
                              {isLoading ? (
                                <div>Loading...</div>
                              ) : (
                                <span className="icon-reverse-wrapper">
                                  <span className="btn-text">Submit</span>
                                  <span className="btn-icon">
                                    <i className="feather-arrow-right" />
                                  </span>
                                  <span className="btn-icon">
                                    <i className="feather-arrow-right" />
                                  </span>
                                </span>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadModal;
