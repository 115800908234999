import {useLogOutMutation} from "@/redux/api/Otp/otp";
import {logout, setAuth} from "@/redux/api/Otp/otpSlice";
import {useGetProfileQuery} from "@/redux/api/user/user";
import Image from "next/image";

import Link from "next/link";
import {useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";

const User = () => {
  const [skip, setSkip] = useState(true);
  const {user} = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const {data} = useGetProfileQuery(undefined, {skip});
  const [logOut] = useLogOutMutation();
  useEffect(() => {
    const tokenString = localStorage.getItem("persist:auth");
    const tokenObj = JSON.parse(tokenString)?.token;

    if (tokenObj && tokenObj !== "null" && tokenObj !== "") {
      setSkip(false);
    } else {
      setSkip(true);
    }

    dispatch(setAuth({user: data?.data}));
  }, [skip, dispatch, data]);
  return (
    <div className="rbt-user-menu-list-wrapper">
      <div className="inner">
        <div className="rbt-admin-profile">
          <div className="admin-thumbnail">
            <Image
              src={`${process.env.NEXT_PUBLIC_FILE_BASE}${user?.profileImageUrl}`}
              width={43}
              height={43}
              alt="User Images"
            />
          </div>
          <div className="admin-info">
            <span className="name">{user?.name}</span>
            <Link
              className="rbt-btn-link color-primary"
              href={`/${user?.role}/${
                user?.role === "superadmin"
                  ? "profile"
                  : `${user?.role}-profile`
              }`}>
              View Profile
            </Link>
          </div>
        </div>

        <hr className="mt--10 mb--10" />

        <ul className="user-list-wrapper">
          {user?.role !== "superadmin" && (
            <li>
              <Link href={`/${user?.role}/${user?.role}-enrolled-course`}>
                <i className="feather-book-open"></i>
                <span>Enrolled Courses</span>
              </Link>
            </li>
          )}

          <li>
            <Link
              href={`/${user?.role}/${
                user?.role === "superadmin"
                  ? "settings"
                  : `${user?.role}-settings`
              }`}>
              <i className="feather-settings"></i>
              <span>Settings</span>
            </Link>
          </li>
          <li>
            <Link
              href="/"
              onClick={async () => {
                const res = await logOut();
                if (res.data.success) {
                  dispatch(logout());
                  localStorage.clear();
                }
              }}>
              <i className="feather-log-out"></i>
              <span>Logout</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default User;
