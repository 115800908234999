import Link from "next/link";

import User from "../Offcanvas/User";
import {useAppContext} from "@/context/Context";
import {useSelector} from "react-redux";
import {useNotificationQuery} from "@/redux/api/instructor/instructor";
import {useEffect, useState} from "react";
import LeadModal from "@/components/LeadModal/LeadModal";
import DarkSwitch from "../Offcanvas/dark-switch";

const HeaderRightTwo = ({btnClass}) => {
  const [showModal, setShowModal] = useState(false);

  const {isLightTheme, toggleTheme} = useAppContext();
  const [skip, setSkip] = useState(true);
  const {mobile, setMobile, search, setSearch, cartToggle, setCart} =
    useAppContext();
  const {data} = useNotificationQuery(undefined, {
    pollingInterval: 60000,
    skip,
  });
  const {user} = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart);

  const tokenString = localStorage.getItem("persist:auth");
  const tokenObj = JSON.parse(tokenString)?.token;
  useEffect(() => {
    if (tokenObj && tokenObj !== "null" && tokenObj !== "") {
      setSkip(false);
    } else {
      setSkip(true);
    }
  }, [skip, user?.token, tokenObj]);
  return (
    <div className="header-right">
      <ul className="quick-access">
        <li className="access-icon">
          <Link
            className={`search-trigger-active rbt-round-btn ${
              (search ? "" : "open",
              isLightTheme === false ? "text-white" : "text-black")
            }`}
            href="#"
            onClick={() => setSearch(!search)}>
            <i className="feather-search"></i>
          </Link>
        </li>

        <li className="access-icon rbt-mini-cart">
          <DarkSwitch isLight={isLightTheme} switchTheme={toggleTheme} />
        </li>
        <li className={`access-icon rbt-mini-cart`}>
          <Link
            className="rbt-cart-sidenav-activation rbt-round-btn"
            href={`/${user?.role}/${
              user?.role === "superadmin" ? "notification" : `notification`
            }`}
            // onClick={() => setCart(!cartToggle)}
          >
            <i className="feather-bell"></i>
            <span className="rbt-cart-count">{data?.data?.length || 0}</span>
          </Link>
        </li>
        <li className="access-icon rbt-mini-cart">
          <Link
            className="rbt-cart-sidenav-activation rbt-round-btn"
            href="#"
            onClick={() => setCart(!cartToggle)}>
            <i className="feather-shopping-cart"></i>
            <span className="rbt-cart-count">{cart?.totalItems || 0}</span>
          </Link>
        </li>
        {tokenObj && tokenObj !== "null" && tokenObj !== "" ? (
          <li className="account-access rbt-user-wrapper  d-xl-block">
            <Link
              href={`/${user?.role}/${
                user?.role === "superadmin"
                  ? "profile"
                  : `${user?.role}-profile`
              }`}>
              <span className="d-flex justify-items-center align-items-center">
                {" "}
                <i className="feather-user"></i>
                <span className="d-none d-xl-block" title={user?.name}>
                  {" "}
                  {user?.name?.slice(0, 5)}
                  {user?.name?.length > 20 ? "..." : ""}
                </span>
              </span>
            </Link>
            <User />
          </li>
        ) : (
          <li className="account-access rbt-user-wrapper  d-xl-block">
            <Link href="/login">লগ ইন</Link>
          </li>
        )}

        {/* <li className="access-icon rbt-user-wrapper d-block d-xl-none">
          <Link className="rbt-round-btn" href="#">
            <i className="feather-user"></i>
          </Link>
          <User />
        </li> */}
      </ul>

      <div
        onClick={() => setShowModal(true)}
        className="rbt-btn-wrapper d-none d-xl-block">
        <Link className={`rbt-btn ${btnClass}`} href="#">
          <span>Join Now</span>
        </Link>
      </div>

      <div className="mobile-menu-bar d-block d-xl-none">
        <div className="hamberger">
          <button
            className="hamberger-button rbt-round-btn"
            onClick={() => setMobile(!mobile)}>
            <i className="feather-menu"></i>
          </button>
        </div>
      </div>

      {showModal && <LeadModal close={() => setShowModal(false)} />}
    </div>
  );
};

export default HeaderRightTwo;
